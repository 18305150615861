// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-archipelago-mdx": () => import("./../../../src/pages/archipelago.mdx" /* webpackChunkName: "component---src-pages-archipelago-mdx" */),
  "component---src-pages-billgo-mdx": () => import("./../../../src/pages/billgo.mdx" /* webpackChunkName: "component---src-pages-billgo-mdx" */),
  "component---src-pages-blog-1-mdx": () => import("./../../../src/pages/blog1.mdx" /* webpackChunkName: "component---src-pages-blog-1-mdx" */),
  "component---src-pages-blog-2-mdx": () => import("./../../../src/pages/blog2.mdx" /* webpackChunkName: "component---src-pages-blog-2-mdx" */),
  "component---src-pages-blog-3-mdx": () => import("./../../../src/pages/blog3.mdx" /* webpackChunkName: "component---src-pages-blog-3-mdx" */),
  "component---src-pages-blog-4-mdx": () => import("./../../../src/pages/blog4.mdx" /* webpackChunkName: "component---src-pages-blog-4-mdx" */),
  "component---src-pages-doux-mdx": () => import("./../../../src/pages/doux.mdx" /* webpackChunkName: "component---src-pages-doux-mdx" */),
  "component---src-pages-hawthorne-mdx": () => import("./../../../src/pages/hawthorne.mdx" /* webpackChunkName: "component---src-pages-hawthorne-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-lab-mdx": () => import("./../../../src/pages/lab.mdx" /* webpackChunkName: "component---src-pages-lab-mdx" */),
  "component---src-pages-resources-mdx": () => import("./../../../src/pages/resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */)
}

